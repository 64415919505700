import * as React from "react";
import { IListDefinition, Alignment } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { since, toDateWithHM } from "ts/core/dateHelpers";
import { ArticleListModel } from "ts/models/shared/ArticleListModel";
import PriceIcon from "svg/price.svg";
import { Button } from "../../../components/shared/Button";
import { Dialog } from "../../../core/dialog";
import { AdjustPricesDialog } from "./AdjustPricesDialog";

const config: IListDefinition<ArticleListModel> = {
  id: "articles",
  singular: "Artikkel",
  editLink: (row) => `/articles/${row.Id}/edit`,
  createLink: _ => null,

  columns: [
    {
      field: "Id",
      sortable: true,
      title: "Id",
      render: (row, value) => row.Deleted == null ? <td><Link to={`/articles/${row.Id}/edit`}>{value}</Link></td> : <td>{value}</td>
    }, {
      title: "Tittel",
      field: "Title",
      sortable: false,
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      title: "Fakturatekst",
      field: "Description",
      sortable: true,
      render: (row, value) => <td>{value}</td>
    }, {
      field: "ArticleTypeText",
      sortable: true,
      title: "Type",
      render: (row, value) => <td className="no-wrap">{value}</td>
    }, {
      title: "Opprettet",
      field: "Created",
      sortable: true,
      alignment: Alignment.Right,
      render: (row) => <td className="right no-wrap"><span title={since(row.Created)}>{toDateWithHM(row.Created)}</span></td>
    }, {
      title: "Priser",
      sortable: false,
      alignment: Alignment.Center,
      render: (row) => <td className="center cell-action"><Link to={`/articles/${row.Id}/prices`} title="Priser"><PriceIcon className="icon" /></Link></td>
    }
  ],
  filters: [],
  presets: []
}

export class ArticleList extends React.Component {

  showAdjustPricesDialog = () => {
    const dialog = new Dialog<any>();
    dialog.title = "Oppdater priser"
    dialog.content = <AdjustPricesDialog />
    dialog.closable = true;
    dialog.buttons = [];
    Dialog.Show(dialog);
  }

  render() {
    return (
      <div className="panel">
        <div style={{ padding: '1rem', float: 'right' }}>
          <Button onClick={() => this.showAdjustPricesDialog()} text="Juster priser" />
        </div>
        <List configuration={config} store={RootStore.getListStore(config)} wrapInPanel={false} />
      </div>
    )
  }
}

