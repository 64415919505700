import * as React from "react";
import { observer } from "mobx-react";
import { Network } from "../../../api/network";
import { toISO8601Date } from "../../../core/dateHelpers";
import { DateSelector } from "../../../components/shared/DatePicker";
import { InputWithFix } from "../../../components/shared/InputWithFix";
import { SimpleWrappedField } from "../../../components/shared/SimpleWrappedField";

interface Props {
  onCloseFn?: () => void;
}

interface State {
  date: Date;
  adjustment: number;
}

@observer
export class AdjustPricesDialog extends React.Component<Props, State> {

  state: State = { date: new Date(), adjustment: 5 };

  async updatePrices() {
    const network = new Network();
    await network.getSimple(`/api/articles/UpdatedPricesForNewPeriode/${(this.state.adjustment / 100.0) + 1}/${toISO8601Date(this.state.date)}`);
    this.props.onCloseFn!();
  }


  render() {
    return <div className="">
      <div>
        <form className="edit-form">
          <SimpleWrappedField label="Start" help="Velg tidspunktet de nye prisene skal gjelde fra.">
          <div className="d-flex">
            <DateSelector
              onChange={value => this.setState(s => ({ ...s, date: value ?? s.date }))}
              dateFormat="dd. MMM yyyy"
              title="Fra og med"
              icon="fa-calendar"
                selected={this.state.date} />
            </div>
          </SimpleWrappedField>
          <SimpleWrappedField label="Justering" help="Velg prisjusteringen i prosent.">
            <InputWithFix
              value={this.state.adjustment}
              onChange={(element) => {
                this.setState(s => ({ ...s, adjustment: parseInt(element.target.value) }))
              }}
              postfix="%"
            />
          </SimpleWrappedField>
          <div className="buttons footer">
            <button type="button" className="button" onClick={e => this.updatePrices()}>Oppdater</button>
          </div>
        </form>
      </div>
    </div>
  }
}