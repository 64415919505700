import PdfIcon from "svg/pdf.svg";
import { List } from "../../../components/lists/List";
import RootStore from "ts/stores/root-store";
import React from "react"
import { Link } from "react-router-dom"
import { Alignment, FilterType, IListDefinition } from "../../../components/lists/IListDefinition"
import { toSerialNumber, EntityType, isNullOrWhitespace } from "../../../core/stringHelpers"
import { ExpenceListModel } from "../../../models/shared/ExpenceListModel";
import { Date, LookupValue, NumberWithPostfix } from "../../../components/shared/display";
import { TextFilter } from "../../../components/lists/FilterComponents/TextFilter";
import { Enums } from "../../../stores/lookup-store";
import { SortDirection } from "../../../models/shared/SortDirection";

const config: IListDefinition<ExpenceListModel> = {
  id: "expences",
  singular: "Utgift",
  createLink: _ => "/expences/create",
  editLink: (row) => `/expences/${row.Id}/edit`,
  columns: [
    {
      title: "Id",
      sortable: true,
      field: "Id",
      render: (row, value) => <td><Link to={`/expences/${row.Id}/edit`}>{toSerialNumber(EntityType.Expence, row.Id)}</Link></td>
    },
    {
      title: "Dato",
      sortable: true,
      field: "Created",
      render: (row, value) => <td><Date date={value} /></td>
    },
    {
      title: "Arbeidsordre",
      sortable: true,
      field: "WorkOrderId",
      render: (row, value) => <td><Link to={`/workorders/${value}`}>{toSerialNumber(EntityType.WorkOrder, value)}</Link></td>
    },
    {
      title: "Utgift",
      sortable: true,
      field: "Amount",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right"><NumberWithPostfix post="kr" decimals={2} number={value} /></td>
    },
    {
      title: "Leverandør",
      sortable: true,
      field: "Supplier",
      render: (row, value) => <td>{value}</td>
    },
    {
      title: "Type",
      sortable: true,
      field: "Type",
      render: (row, value) => <td><LookupValue enum={Enums.ExpenceType} value={value} /></td>
    },
    {
      title: "Leverandørreferanse",
      sortable: true,
      field: "SupplierReference",
      render: (row, value) => <td>{value}</td>
    },
    {
      title: "Tripletex",
      sortable: true,
      field: "TripletexId",
      render: (row, value) => {
        if (value == null || value === 0)
          return <td />
        else
          return <td><a href={`/api/economy/redirectToTripletexForSupplierInvoice/${value}`}>{value}</a></td>
      }
    },
    {
      title: "",
      sortable: false,
      field: "TripletexId",
      render: (row, value) => {
        if (value == null || value === 0)
          return <td />
        else
          return <td><a href={`/api/economy/getSupplierInvoicePdf/${value}/${row.SupplierReference ?? value}`} target="_blank" download><PdfIcon /></a></td>
      }
    },
  ],
  filters: [
    {
      field: "WorkOrderId",
      title: "Arbeidsordre",
      component: <TextFilter />,
      type: FilterType.String
    },
    {
      field: "Supplier",
      title: "Leverandør",
      component: <TextFilter />,
      type: FilterType.String
    },
    {
      field: "SupplierReference",
      title: "Referanse",
      component: <TextFilter />,
      type: FilterType.String
    },

  ],
  presets: [
    {
      default: true,
      title: "Opprettet",
      filters: [],
      sort: [{
        field: "Created",
        direction: SortDirection.Desc
      }]
    }
  ]
}

export class ExpenceList extends React.Component {
  render() {
    return <List configuration={config} store={RootStore.getListStore(config)} />
  }
}